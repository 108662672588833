import { css } from "@emotion/react"
import tw from "twin.macro"

export const noScrollbar = css({
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
})

export const dekSpacing = tw`mt-10 lg:mt-14 mb-14 lg:mb-20`
